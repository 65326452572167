import { formatDiagnosticsWithColorAndContext } from "typescript";

// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Edmond",
  middleName: "",
  lastName: "De Ruyter",
  message: "Aspiring Postgraduate Student MBA - Leadership and Change Management. Degree in Business Management - Marketing.",
  icons: [
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/edmond-de-ruyter/",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/edmond.deruyter/",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/edmond_de_ruyter/",
    },
  ],
};

// ABOUT SECTION

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/Edmond.png"),
  imageSize: 375,
  message:
    "My name is Edmond De Ruyter, an aspiring student in the Master Business Administration (MBA) specializing in Leadership and Change Management. I'm passionate about responsible business management and innovative change! I cherish personal growth opportunities and constantly look for ways to further deepen my knowledge and skills.",
  message2:"At 24 years of age, I reside near Hasselt, Belgium. Prior to embarking on my MBA journey, I successfully obtained a bachelor's degree in Business Management - Marketing from the University College, UCLL. With my graduation set for June 2024, I am actively exploring career opportunities that offer significant growth potential. I firmly believe that the combination of my marketing bachelor's degree and my forthcoming MBA will provide substantial added value to my professional pursuits.",
  resume: require("../editable-stuff/resume.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: "Recent Projects",
  gitHubUsername: "skillybilly",
  reposLength: 4,
  specificRepos: [],
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Dutch", value: 100 },
    { name: "English", value: 95 },
    { name: "French", value: 50 },
    { name: "Digital Marketing", value: 75 },
    { name: "Strategic Management", value: 80 },
    { name: "Market Research", value: 70 },
    { name: "Consumer Psychology", value: 70 },
    { name: "Intercultural Communication", value: 80 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 95 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ]
};

// highlights SECTION
const highlights = {
  show: true,
  heading: "Accomplishments",
  message:
    "I prioritize personal growth and have a strong desire to venture into the unknown. Here are a few significant achievements from my academic and professional journey. In addition to earning a Bachelor's degree in Business Management with a focus on Marketing, I also successfully completed the Google Garage certificate program in Fundamentals Of Digital Marketing. Shortly thereafter, I achieved certification in Inbound Sales from HubSpot Academy. Furthermore, I completed an online course on Unified Modeling Language (UML) and received certification upon passing the exam",
  message3: "Building upon my bachelor's degree (in applied science) I am currently augmenting my expertise by obtaining a master’s degree in the MBA Leadership and Change Management.",
  images: [
    { 
      img: require("../editable-stuff/UCLL.png"), 
      label: " ", 
      paragraph: " "
    },
    { 
      img: require("../editable-stuff/Certificate.png"), 
      label: " ", 
      paragraph: " "
    },
    { 
      img: require("../editable-stuff/CertificateUML.png"), 
      label: " ", 
      paragraph: " "
    },
    { 
      img: require("../editable-stuff/CertificateSales.png"), 
      label: " ", 
      paragraph: " "
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};



// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
  "I'm currently in the final year of my MBA program, specializing in Leadership and Change Management. If you'd like to discuss potential collaboration opportunities as I approach my future career, have any inquiries, or simply want to say hello, please don't hesitate to reach out to me via",
  email: "the contact section",
};

// timeline SECTION
const timeline = {
  show: true,
};

//portfolio SECTION
const portfolio = {
  show: false,
};

// contact SECTION
const contact = {
  show: true,
};

// Blog SECTION
const blog = {
  show: true,
  heading: "Follow my profesional life via my blog",
};


export { navBar, mainBody, about, repos, highlights, skills, getInTouch, timeline, portfolio, contact,blog };
