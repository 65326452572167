import React from "react";
import Container from "react-bootstrap/Container";

const Footer = (props) => {
  const bgStyle = { backgroundColor: "#f5f5f5" };

  return (
    <footer style={bgStyle} className="mt-auto py-5 text-center ">
      <Container>
        {props.children}
         Copyright ©2019 All rights reserved by <i className="fas fa-user" /> {" "}
        <a
          className="badge badge-dark"
          rel="noopener"
          href="https://www.linkedin.com/in/edmond-de-ruyter/"
          aria-label="My LinkedIn"
        >
          Edmond De Ruyter
        </a>{" "}
        <p>
          <small className="text-muted">
            Thank you for visiting my personal website!
          </small>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
